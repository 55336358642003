<template>
  <div class="line-chart-container" :id="id">
    <!-- 折线图 -->
  </div>
</template>
<script>
export default {
  name: "LineChart",
  props: {
    id: {
      type: String,
      default: function () {
        return "line-chart-" + this.$utils.uuid();
      },
    },
    chartOpt: {
      type: Object,
    },
    datas:{
      type: Object
    }
  },
  data() {
    return {
      option: {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            label: {
              backgroundColor: "#6a7985",
            },
          },
        },
        legend: {
          show: false,
          right: 0,
          textStyle: {
            color: "rgba(243,255,255,0.8)",
          },
        },
        color: ["#12C763"],
        grid: {
          show: true,
          left: "20px",
          right: "20px",
          bottom: "20px",
          containLabel: true,
          borderColor: '#404756'
        },
        xAxis: [
          {
            type: "category",
            boundaryGap: true,
            axisTick: {
              show: false,
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: "#404756",
                type: "solid",
                width: 2,
              },
            },
            axisLabel: {
              color: "#E7F8FB",
              fontSize: "12px",
            },
          },
        ],
        yAxis: [
          {
            name: "单位：平方米",
            nameTextStyle: {
              color: '#E7F8FB',
              fontFamliy: 'Microsoft YaHei',
              fontWeight: 400,
              fontSize: '12px'
            },
            type: "value",
            splitLine: {
              lineStyle: {
                type: "sold",
                color: "#404756",
              },
            },
            axisLabel: {
              color: "#E7F8FB",
              fontSize: "12px",
            },
          },
        ],
        series: [
          {
            name: "面积",
            type: "line",
            itemStyle: {
            },
            smooth: true,
            emphasis: {
              focus: "series",
            },
            data: [120, 132, 310, 134, 90, 230, 210],
            areaStyle: {
              //区域填充渐变颜色
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: "rgba(21,254,254, 0.5)", // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: "rgba(255,255,255, 0)", // 100% 处的颜色
                  },
                ],
                global: false, // 缺省为 false
              },
            },
          }
        ],
      },
      lineChart: null,
    };
  },
  mounted() {
    const chartDom = document.getElementById(this.id);
    this.lineChart = this.$echarts.init(chartDom);
    this.draw();
  },
  watch: {
    datas: {
      handler: function (val) {
        if (val) {
          this.draw();
        }
      },
      deep: true,
    },
  },
  methods: {
    draw() {
      if(this.option.legend){
        this.option.legend.data = this.datas.legend
      }
      this.option.xAxis[0].data = this.datas.xaxis
      this.option.series.map((item, index) => {
        item.name = this.datas.legend[index]
        item.data = this.datas.dataArr[index]
      })
      this.lineChart.setOption(this.option);
    },
  },
};
</script>
<style lang="scss" scoped>
.line-chart-container {
  width: 100%;
  height: 100%;
}
</style>