<template>
  <div class="map-chart-container">
    <div class="title">
      各地市林地面积(万亩)
    </div>
    <div class="map" :id="id">
      <!-- 地图 -->
    </div>
    <div :class="`mark-point-box`" v-show="show">

      <div class="mark-item-container" ref="box">
        <div class="box-title">{{tableData.title}}</div>
        <div class="mark-item">
          <div class="mark-item-label">编码：</div>
          <div class="mark-item-val">
            FP41003
          </div>
        </div>
        <div class="mark-item">
          <div class="mark-item-label">类型：</div>
          <div class="mark-item-val">
            FP
          </div>
        </div>

        <div class="mark-item">
          <div class="mark-item-label">级别：</div>
          <div class="mark-item-val">
            国家级
          </div>
        </div>

        <div class="mark-item">
          <div class="mark-item-label">行政区划：</div>
          <div class="mark-item-val">
            河南平顶山汝州市
          </div>
        </div>

        <div class="mark-item">
          <div class="mark-item-label">面积（公顷）：</div>
          <div class="mark-item-val">
            5375.83
          </div>
        </div>

        <div class="mark-item">
          <div class="mark-item-label">批复面积：</div>
          <div class="mark-item-val">
            4900.37
          </div>
        </div>

        <div class="mark-item">
          <div class="mark-item-label">批复时间：</div>
          <div class="mark-item-val">
            -
          </div>
        </div>

        <div class="mark-item">
          <div class="mark-item-label">批准文号：</div>
          <div class="mark-item-val">
            林造批字【1992
          </div>
        </div>

        <div class="mark-item">
          <div class="mark-item-label">调整批复文：</div>
          <div class="mark-item-val">
            -
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import mapJson from "@/assets/mapJson/henansheng.json";
import point1 from '@/assets/images/point2.jpg'
export default {
  name: "MapChart",
  props: {
    id: {
      type: String,
      default: function () {
        return "map-chart-" + this.$utils.uuid();
      },
    },
    chartOpt: {
      type: Object,
    },
    datas: {
      type: Object,
      default: function () {
        return {
          dataArr: []
        };
      },
    },
  },
  data() {
    return {
      show: false,
      mapData: [
        {
          name: "濮阳市",
          code: "puyang",
          value: 120,
          finish: 2000,
          doing: 234,
        },
        {
          name: "安阳市",
          code: "anyang",
          value: 227.21,
          finish: 12000,
          doing: 2344,
        },
        {
          name: "鹤壁市",
          code: "hebi",
          value: 49.18,
          finish: 2200,
          doing: 555,
        },
        {
          name: "新乡市",
          code: "xinxiang",
          value: 189.05,
          finish: 200,
          doing: 999,
        },
        {
          name: "焦作市",
          code: "jiaozuo",
          value: 125.56,
          finish: 1000,
          doing: 233344,
        },
        {
          name: "济源市",
          code: "jiyuan",
          value: 129,
          finish: 4000,
          doing: 24,
        },
        {
          name: "三门峡市",
          code: "sanmenxia",
          value: 912.75,
          finish: 20030,
          doing: 10,
        },
        {
          name: "洛阳市",
          code: "luoyang",
          value: 1278,
          finish: 202,
          doing: 2340,
        },
        {
          name: "郑州市",
          code: "zhengzhou",
          value: 314.98,
          finish: 20,
          doing: 234,
        },
        {
          name: "开封市",
          code: "kaifeng",
          value: 113.05,
          finish: 123,
          doing: 234,
        },
        {
          name: "商丘市",
          code: "shangqiu",
          value: 119.8,
          finish: 4000,
          doing: 234,
        },
        {
          name: "平顶山市",
          code: "pingdingshan",
          value: 292.6,
          finish: 9000,
          doing: 234,
        },
        {
          name: "许昌市",
          code: "xuchang",
          value: 122.77,
          finish: 9000,
          doing: 234,
        },
        {
          name: "周口市",
          code: "zhoukou",
          value: 240,
          finish: 9000,
          doing: 234,
        },
        {
          name: "漯河市",
          code: "luohe",
          value: 12.21,
          finish: 9000,
          doing: 234,
        },
        {
          name: "南阳市",
          code: "nanyang",
          value: 1468,
          finish: 9000,
          doing: 234,
        },
        {
          name: "驻马店市",
          code: "zhumadian",
          value: 489,
          finish: 9000,
          doing: 234,
        },
        {
          name: "信阳市",
          code: "xinyang",
          value: 761.7,
          finish: 9000,
          doing: 234,
        },
      ],
      tableData: {
        title: ''
      },
      option:{
        tooltip: {
          show: false,
          enterable: true,
          confine: true,
          backgroundColor: 'transparent',
          borderColor: 'transparent',
          borderWidth: 0,
          padding: 0,
          formatter: (params)=>{
            this.show = true
            this.tableData.title = params.data.name
            return this.$refs.box
          }
        },
        visualMap: {
          min: 0,
          max: 1500,
          text: ["高", "低"],
          realtime: true,
          calculable: true,
          color: ["#3CA25F", "#195D10"],//颜色
          right: '100px',
          textStyle: {
            color: '#6DFF58',
            fontFamily: 'Microsoft YaHei',
            fontWeight: 400,
            fontSize: '14px',
          }
        },
        series: [
          {
            type: 'map3D',
            name: '地图',
            height: '113%',
            // 相对于父容器比例
            center: ['50%', '50%'],
            selectedMode: 'single', // 地图高亮单选
            regionHeight: 6, // 地图高度
            map: 'henan',
            viewControl: {
              // 缩放大小，数值越大，地图越小
              distance: 130,
              // 上下倾斜角度
              alpha: 65,
              // 左右倾斜角度
              beta: 3,
              rotateSensitivity: 0,
              autoRotate:false,
              zoomSensitivity: false,
            },
            label: {
              show: true, // 是否显示名字
              color: '#fff', // 文字颜色
              fontSize: '12px', // 文字大小

              backgroundColor: 'rgba(5,4,41,0.79)',
              borderColor: '#4986D5',
              borderWidth: 1,
              borderRadius: 5,
              padding: 3,
            },
            itemStyle: {
              color: 'rgba(24,144,255,1)', // 地图背景颜色
              borderWidth: 1, // 分界线wdith
              borderColor: '#fff', // 分界线颜色
              opacity: 0.92,
              borderType: "dashed",
            },
            emphasis: {
              // label: {
              //   show: true, // 是否显示高亮
              //   textStyle: {
              //     color: '#fff' // 高亮文字颜色
              //   },
              //   formatter: ({data})=>{
              //     return `{point1|}`
              //   },
              //   rich: {
              //     point1: {
              //       width: 40,
              //       height: 40,
              //       backgroundColor:{
              //         image:point1
              //       } ,
              //       borderRadius: 15,
              //       padding: [0, 0, 0, 0],
              //       fontFamily: 'Microsoft YaHei',
              //       fontWeight: 400,
              //       fontSize: '16px',
              //       fontStyle: 'italic'
              //     }
              //   }
              // },
              itemStyle: {
                color: '#00baff', // 地图高亮颜色
                borderWidth: 10, // 分界线wdith
                borderColor: '#6BECF5' // 分界线颜色
              }
            },
            markPoint: {
              symbol: "circle",
              symbolSize: 50,
            },
            left: '-90px',
            top: '-13%',
            zoom: 1,
            data: [],
            light: {
              main: {
                color: '#fff',
                intensity: 1,
                shadow: true,
                shadowQuality: 'high',
                alpha: 25, //
                beta: 20
              },
              ambient: {
                color: '#fff',
                intensity: 0.6
              }
            }
          }
        ]
      },
      chart: null,
      pointer: 0,
      timer: null,
    };
  },
  watch: {
    'datas.dataArr':{
      handler(val){
        if(val){
          this.mapData.forEach(item => {
            const obj = val.find((data)=>{
              return data.code == item.code
            })
            this.$set(item, 'value', obj.value)
            this.$set(item, 'finish', obj.finish)
            this.$set(item, 'doing', obj.doing)
          });
        }
      },
      immediate: true,
      deep: true
    }
  },
  computed: {
  },
  mounted() {
    const chartDom = document.getElementById(this.id);
    this.chart = this.$echarts.init(chartDom);
    this.$echarts.registerMap("henan", mapJson);
    this.draw();
    this.initEvent();
  },
  methods: {
    initEvent() {
      this.chart.off('click')
      this.chart.on("click", ({ dataIndex }) => {
        // 禁止选中高亮
      });
    },
    draw() {
      this.option.series[0].data = this.mapData;
      this.chart.setOption(this.option);
    },
  },
};
</script>
<style lang="scss" scoped>
.map-chart-container,
.map {
  position: relative;
  width: 100%;
  height: 100%;
}
.title{
  width: 149px;
  height: 20px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  font-size: 20px;
  color: #E7F8FB;
  line-height: 20px;
  position: absolute;
  left: 62px;
  top: 30px;
  padding-left: 13px;
  white-space: nowrap;
  &::before{
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 5px;
    height: 5px;
    background: #6DFF58;
    border-radius: 50%;
  }

}
@keyframes cycle
{
    from {
      width: 15px;
      height: 15px;
    }
    to {
      width: 50px;
      height: 50px;
    }
}
.map-chart-container {
  position: relative;
  .mark-point-box {
  }
}

  // 标记浮窗显示的内容
  .mark-item-container {
    // left: 420px;
    // top: 115px;
    // position: ;
    width: 284px;
    height: 368px;
    background: url('../../images/dialog-bg.png') center center no-repeat;
    background-size: 100% 100%;
    display: block;
    padding: 30px 10px;
    .box-title{
      line-height: 1;
      text-align: left;
      position: absolute;
      left: 4px;
      top: 6px;
      width: 100%;
      height: 15px;
      font-family: Adobe Heiti Std;
      font-weight: normal;
      font-size: 14px;
      color: #FFFFFF;
      line-height: 15px;
      white-space: nowrap;
    }
    .mark-item {
      margin-top: 5px;
      position: relative;
      width: 266px;
      height: 29px;
      line-height: 29px;
      display: flex;
      font-family: Microsoft YaHei;
      font-weight: 400;
      font-size: 14px;
      color: #fff;
      padding: 0 10px 0 13px;
      .mark-item-label{
        text-align: left;
        width: 100px;
        white-space: nowrap;
        color: #fff;
        z-index: 2;
        position: relative;
      }
      .mark-item-val{
        flex: 1;
        color: #fff;
        z-index: 2;
        position: relative;
        text-align: right;
      }
      &::after {
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        background: #152E83;
        opacity: 0.75;
        position: absolute;
        margin: auto;
        top: 0;
        left: 0;
        z-index: 0;
      }
    }

    .left-top-corner,
    .left-bottom-corner,
    .right-top-corner,
    .right-bottom-corner {
      width: 11px;
      height: 11px;
      display: block;
      background-color: transparent;
      position: absolute;
      border: 1px solid #00e5ff;
    }
    .left-top-corner {
      left: 0;
      top: 0;
      border-right-color: transparent;
      border-bottom-color: transparent;
    }
    .left-bottom-corner {
      left: 0;
      bottom: 0;
      border-right-color: transparent;
      border-top-color: transparent;
    }
    .right-top-corner {
      right: 0;
      top: 0;
      border-left-color: transparent;
      border-bottom-color: transparent;
    }
    .right-bottom-corner {
      right: 0;
      bottom: 0;
      border-left-color: transparent;
      border-top-color: transparent;
    }
  }
</style>
