<template>
  <div class="pie-chart-container" :id="id">
    <!-- 饼状图 -->
  </div>
</template>
<script>
export default {
  name: "PieChart",
  props: {
    id: {
      type: String,
      default: function () {
        return "pie-chart-" + this.$utils.uuid();
      },
    },
    chartOpt: {
      type: Object,
    },
    datas: {
      type: Array,
    },
  },
  data() {
    return {
      option: {
        tooltip: {
          show: false,
        },
        color: ['#16E3A7', '#018BF1', '#EBF58E', '#71BFFF', '#F85A08'],
        legend: {
          y: "center",
          align: "left",
          right: "5%",
          itemGap: 15,
          orient: "vertical",
          icon: "circle",
          itemHeight: 8,
          formatter:  (name) => {
            return this.getLgendName(name) || ''
          },
          textStyle: {
            color: "#ffffff",
            fontSize: "12px",
          },
          itemStyle: {
            borderColor: "transparent",
          },
        },
        series: [
          {
            center: ['30%', '50%'],
            z: 1,
            name: "森林类型",
            type: "pie",
            radius: ["40%", "60%"],
            avoidLabelOverlap: true,
            label: {
              show: false,
              color: "rgba(255,255,255,0.8)",
              // position: 'center',
              formatter: "{d}%\n{b}",
            },
            itemStyle: {
              borderColor: "#0e1631",
              borderWidth: 5,
            },
            emphasis: {
              scaleSize: 10,
              label: {
                show: false,
              },
            },
            labelLine: {
              show: false,
            },
            data: [
            ],
          },
          {
            center: ['30%', '50%'],
            z: 2,
            name: "森林类型",
            type: "pie",
            radius: ["40%", "60%"],
            avoidLabelOverlap: true,
            itemStyle: {
              borderColor: "#0e1631",
              borderWidth: 5,
            },
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              itemStyle:{
                opacity: 0
              },
              label: {
                show: true,
                fontSize: '20',
                fontFamily: 'Microsoft YaHei',
                color: "#FFFFFF",
                fontWeight: 'bold',
                formatter: "{b}\n{c}",
              }
            },
            labelLine: {
              show: false,
            },
            data: [
            ],
          },
        ],
      },
      chart: null,
      timer: null,
      pointer: 0,
    };
  },
  mounted() {
    const chartDom = document.getElementById(this.id);
    this.chart = this.$echarts.init(chartDom);
    this.draw();
    this.beginCycle();
    this.chart.on("mouseover", (e) => {
      clearInterval(this.timer);
      if (e.dataIndex != this.pointer) {
        this.pointer = e.dataIndex;
        this.selectArea(this.pointer);
      }
    });

    this.chart.on("mouseout", (e) => {
      this.pointer = e.dataIndex;
      this.beginCycle();
    });
  },
  watch: {
    datas: {
      handler: function (val) {
        if (val) {
          this.draw();
        }
      },
      deep: true,
    },
  },
  computed: {
    dataIndexArr() {
      return this.datas.map((item, index) => {
        return index;
      });
    },
  },
  methods: {
    getLgendName(name){
      const _obj = this.datas.find(item => item.name == name)
      const _total = this.datas.reduce((prev,cur)=>{
        return {
          value: cur.value + prev.value
        }
      })
      const _percent = (_obj.value / _total.value).toFixed(2) * 100
      return `${name} \t ${_obj.value}|${_percent}%`
    },
    selectArea(dataIndex = 0) {
      this.chart.dispatchAction({
        type: "downplay",
        seriesIndex: 0,
        dataIndex: this.dataIndexArr,
      });
      this.chart.dispatchAction({
        type: "downplay",
        seriesIndex: 1,
        dataIndex: this.dataIndexArr,
      });
      this.chart.dispatchAction({
        type: "highlight",
        seriesIndex: 0,
        dataIndex,
      });
      this.chart.dispatchAction({
        type: "highlight",
        seriesIndex: 1,
        dataIndex,
      });
    },
    beginCycle() {
      // 开启循环
      if (this.timer) {
        clearInterval(this.timer);
      }
      this.selectArea(this.pointer);
      this.timer = setInterval(() => {
        const _len = this.datas.legnth || 5;
        if (++this.pointer >= _len) {
          // 超出数据数量
          this.pointer = 0;
        }
        this.selectArea(this.pointer);
      }, 2000);
    },
    draw() {
      this.option.series[0].data = this.datas;
      this.option.series[1].data = this.datas;
      this.chart.setOption(this.option);
    },
  },
};
</script>
<style lang="scss" scoped>
.pie-chart-container {
  width: 100%;
  // margin-left: -20%;
  height: 100%;
  position: relative;
}
</style>