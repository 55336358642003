<template>
  <div class="large-screen-container flex flex-col">
    <Header title="森林资源监测"></Header>
    <div class="body flex-1 flex">
      <div class="top">
        <div class="top-item">
          <div class="item-left">
            <div class="left-top">
              <i class="icon slmj-icon"></i>
              <span class="text">
                森林面积(万亩)
              </span>
            </div>
            <div class="left-bottom">
              <countTo
                :startVal="0"
                :endVal="6047.7"
                :duration="2000"
                :decimals="2"
                separator=""
              ></countTo>
            </div>
          </div>
          <div class="item-right">
            <div class="right-top">
              占林地面积
            </div>
            <div class="right-bottom">
              77.42%
            </div>
          </div>
<!--          <div class="item-right">-->
<!--            <div class="right-top">-->
<!--              <div class="info-label">-->
<!--                占林地面积-->
<!--              </div>-->
<!--              <div class="right-bottom">-->
<!--                79.85%-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="right-bottom">-->
<!--              <div class="info-label">-->
<!--                覆盖率-->
<!--              </div>-->
<!--              <div class="info-val">-->
<!--                17.95%-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
        </div>

        <div class="top-item">
          <div class="item-left">
            <div class="left-top">
              <i class="icon lmlhl-icon"></i>
              <span class="text">
<!--                林木绿化率-->
                森林蓄积量（亿立方米）
              </span>
            </div>
            <div class="left-bottom">
              2.07
            </div>
          </div>
          <div class="item-right">
            <div class="right-top">
              森林覆盖率
            </div>
            <div class="right-bottom">
              24.14%
<!--              <countTo-->
<!--                :startVal="0"-->
<!--                :endVal="13040.5"-->
<!--                :duration="2000"-->
<!--                :decimals="1"-->
<!--                separator=""-->
<!--              ></countTo>-->
            </div>
          </div>
        </div>

        <div class="top-item">
          <div class="item-left">
            <div class="left-top">
              <i class="icon lmlhl-icon"></i>
              <span class="text">
                林地面积(万亩)
              </span>
            </div>
            <div class="left-bottom">
               <countTo
                :startVal="0"
                :endVal="7811.1"
                :duration="2000"
                :decimals="2"
                separator=""
              ></countTo>
            </div>
          </div>
          <div class="item-right">
            <div class="right-top">
              占全省土地面积
            </div>
            <div class="right-bottom">
              31.18%
            </div>
          </div>
        </div>

        <div class="top-item">
          <div class="item-left">
            <div class="left-top">
              <i class="icon fldmj-icon"></i>
              <span class="text">
                湿地总面积(万亩)
              </span>
            </div>
            <div class="left-bottom">
               <countTo
                :startVal="0"
                :endVal="942"
                :duration="2000"
                :decimals="2"
                separator=""
              ></countTo>
            </div>
          </div>
          <div class="item-right">
            <div class="right-top">
              草原资源面积（万亩）
            </div>
            <div class="right-bottom">
              404.23
            </div>
          </div>
        </div>

      </div>
      <div class="content">
        <div class="left">
          <div class="section">
            <div class="lzmj-section">
              <div class="section-title">
                林种面积
              </div>
              <div class="section-content">
                <pie-chart :datas="pieDatas"></pie-chart>
              </div>
            </div>
            <div class="fgl-lhl-section">
              <div class="section-title">
                各地市森林覆盖率与林木绿化率
              </div>
              <div class="section-content">
                <BarChart :datas="barDatas"></BarChart>
              </div>
            </div>
          </div>
          <div class="section long-section">
            <div class="section-title">
              人均森林面积
            </div>
            <div class="section-content">
              <line-chart :datas="lineDatas"></line-chart>
            </div>
          </div>
          <div class="section long-section">
            <div class="section-title">
              各地市湿地面积
            </div>
            <div class="section-content">
              <BarChart :datas="barDatas1"></BarChart>
            </div>
          </div>
        </div>
        <div class="right">
          <div class="section section-map">
            <MapChart :datas="mapDatas"></MapChart>
          </div>
          <div class="section long-section">
            <div class="section-title">
              各地市公益林面积
            </div>
            <div class="section-content">
              <BarChart :datas="barDatas2"></BarChart>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import flexs from "@/mixins/flexs.js";
import PieChart from "./components/pie-chart";
import LineChart from "./components/line-chart";
import BarChart from "./components/bar-chart";
import MapChart from "./components/map-chart";
import Header from '../../components/header'

export default {
  components: {
    PieChart,
    LineChart,
    BarChart,
    MapChart,
    Header
  },
  data() {
    return {
      pieDatas: [
          { value: 100, name: "防护林" },
          { value: 90, name: "特用林" },
          { value: 80, name: "用材林" },
          { value: 70, name: "薪炭林" },
          { value: 60, name: "经济林" },
      ],
      lineDatas:{
        legend: ["日常"],
        xaxis: ["2012年","2013年","2014年","2015年","2016年","2017年", "2018年", "2019年", "2020年", "2021年", "2022年", "2023年"],
        dataArr: [
          [101, 132, 140, 180, 220, 260, 280, 300, 320, 360, 370, 380],
        ]
      },
      barDatas:{
        legend: ["森林", "林木"],
        xaxis: ["郑州","洛阳","南阳","许昌","周口","新乡", "商丘", "驻马店", "信阳", "平顶山", "开封", "安阳", "焦作"],
        dataArr: [
          [380, 270, 180, 170, 130, 120, 100, 90, 80, 50, 30, 15, 10],
          [280, 180, 150, 140, 120, 110, 90, 70, 60, 60, 20, 10, 5],
        ]
      },
      barDatas1:{
        yAxis: {
          name: '单位：公顷'
        },
        legendShow: false,
        legend: ["面积"],
        xaxis: ["郑州","洛阳","南阳","许昌","周口","新乡", "商丘", "驻马店", "信阳", "平顶山", "开封", "安阳", "焦作", "濮阳", "漯河", "三门峡", "鹤壁", "济源"],
        dataArr: [
          [380, 270, 180, 170, 130, 220, 100, 90, 80, 50, 160, 15, 10, 50, 80, 160, 90, 20],
        ]
      },
      barDatas2:{
        yAxis: {
          name: '单位：公顷'
        },
        stack: 'common',
        legendShow: false,
        legend: ["一致数据", "土地调查", "林业调查"],
        xaxis: ["郑州","洛阳","南阳","许昌","周口","新乡", "商丘", "驻马店", "信阳", "平顶山", "开封", "安阳", "焦作", "濮阳", "漯河", "三门峡", "鹤壁", "济源"],
        dataArr: [
          [380, 270, 180, 170, 130, 220, 100, 90, 80, 50, 160, 15, 10, 50, 80, 160, 90, 20],
          // [200, 30, 100, 140, 30, 20, 10, 190, 280, 150, 260, 150, 60, 20, 90, 60, 40, 10],
          // [180, 20, 80, 70, 30, 20, 20, 40, 8, 250, 360, 152, 120, 250, 180, 260, 190, 60],
        ]
      },
      mapDatas: {

      },
    };
  },
  mixins: [flexs],
  computed: {
  },
};
</script>
<style lang="scss">
@import "../../assets/css/index.scss";
</style>
<style lang="scss" scoped>
.large-screen-container {
  height: 100%;
  overflow: hidden;
  background: url('./images/bg.png') center center no-repeat;
  background-size: 100% 100%;
  padding: 0 44px 37px 44px;
  .body{
    flex-direction: column;
    .top{
      display: flex;
      flex-wrap: nowrap;
      width: calc(100% - 14px);
      margin-left: 7px;
      height: 111px;
      border-radius: 4px;
      justify-content: space-around;
      position: relative;
      overflow: hidden;

      &::after{
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(0deg, #09BBFE 0%, #5A42EC 100%);
        opacity: 0.3;
      }
      .top-item{
        padding: 14px 0 26px 0;
        width: 444px;
        position: relative;
        display: flex;
        justify-content: space-around;
        >div{
          position: relative;
          z-index: 1;
        }
        &::after{
          content: "";
          width: 3px;
          height: 84px;
          display: block;
          position: absolute;
          right: 0;
          top: 0;
          bottom: 0;
          margin: auto;
          background: url('./images/division-line.png') center center no-repeat;
          background-size: 100% 100%;
          z-index: 0;
        }

        &:last-of-type::after{
          display: none;
        }

        .left-top{
          display: flex;
          .icon{
            width: 29px;
            height: 27px;
            display: block;
            margin-right: 12px;
            &.slmj-icon{
              background: url('./images/slmj.png') center center no-repeat;
              background-size: 100% 100%;
            }
            &.lmlhl-icon{
              background: url('./images/lmlhl.png') center center no-repeat;
              background-size: 100% 100%;
            }
            &.fldmj-icon{
              background: url('./images/fldmj.png') center center no-repeat;
              background-size: 100% 100%;
            }
          }
          .text{
            flex: 1;
            height: 27px;
            line-height: 27px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            font-size: 16px;
            color: #00B0ED;
            background: linear-gradient(0deg, #84F5DE 0%, #56E8F2 50%, #4CC5F8 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }

        .left-bottom{
          text-align: center;
          margin-top: 10px;
          font-family: PangMenZhengDao;
          font-weight: 400;
          font-size: 35px;
          color: #FFFFFF;
          line-height: 36px;
        }

        .item-right{
          .right-top{
            display: flex;
            white-space: nowrap;
          }

          .info-label{
            width: 79px;
            white-space: nowrap;
            font-family: Microsoft YaHei;
            font-weight: 400;
            font-size: 16px;
            color: #00B0ED;
            line-height: 36px;
            background: linear-gradient(0deg, #84F5DE 0%, #56E8F2 50%, #4CC5F8 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
          .info-val{
            margin-left: 18px;
            white-space: nowrap;
            width: 55px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            font-size: 16px;
            color: #00B0ED;
            line-height: 36px;
            background: linear-gradient(0deg, #84F5DE 0%, #56E8F2 50%, #4CC5F8 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
          .right-top{
            width: 170px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            font-size: 16px;
            color: #00B0ED;
            line-height: 36px;
            background: linear-gradient(0deg, #84F5DE 0%, #56E8F2 50%, #4CC5F8 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            margin-top: -5px;
            white-space: nowrap;
          }
          .right-bottom{
            margin-top: 6px;
            display: flex;
            width: 136px;
            font-family: PangMenZhengDao;
            font-weight: 400;
            font-size: 35px;
            color: #FFFFFF;
            line-height: 36px;
          }
        }
      }
    }
    .content{
      height: calc(100% - 243px);
      margin-top: 10px;
      display: flex;
      flex-wrap: nowrap;
      .left{
        flex: 1;
      }
      .right{
        flex: 1;
        margin-left: 17px;
        flex-direction: column;
        .section-map{
          height: 542px;
        }
      }
      .section{
        display: flex;
        .lzmj-section{
          width: 365px;
          height: 270px;
          background: url('./images/lzmj-border.png') center center no-repeat;
          background-size: 100% 100%;
        }
        .fgl-lhl-section{
          width: 536px;
          margin-left: 3px;
          height: 270px;
          background: url('./images/lmlhl-border.png') center center no-repeat;
          background-size: 100% 100%;
        }
        &.long-section{
          margin-top: 3px;
          width: 903px;
          height: 269px;
          background: url('./images/rjslmj-border.png') center center no-repeat;
          background-size: 100% 100%;
          display: block;
        }
        .section-title{
          margin-left: 56px;
          width: auto;
          font-family: Microsoft YaHei;
          font-weight: 400;
          font-size: 24px;
          color: #D9EAF5;
          font-style: italic;
          text-shadow: 0px 3px 0px rgba(2,13,37,0.3);
          background: linear-gradient(0deg, rgba(100,206,255,0.71) 0%, rgba(250,255,255,0.71) 45.9228515625%, rgba(255,255,255,0.71) 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          text-align: left;
        }
        .section-content{
          height: calc(100% - 27px);
        }
      }
    }
  }
}
</style>
